<app-header [oneHeader]="header" [adminFlag]="true"></app-header>
<div class="addUser">
    <img (click)="navigateBack()" src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
        style="width: 36px;margin-left: 22px;cursor: pointer; align-self: baseline;"  />
    <div
        style="display:flex; flex-direction:row;  gap:20px; width: 80vw; height:75vh; align-self:center; justify-self:center ">

        <div class="grid-container">
            <div class="grid-item">
                <label for="name" class="addUserLabel">First Name<b>*</b></label> <br>
                <input type="text" class="form-control addUserInput" (keydown)="onKeyDown('first',$event)" [(ngModel)]="userData.first_name" (input)="checkDataLength(userData.first_name,'first')"> 
                
                <span class="err_ele_span" *ngIf="firstNameLimitErr">
                    <img src="../../../assets/error_icon.svg" alt="">
                    <span>Only 15 characters are allowed.</span>
                </span>
                
                <br>
                <label for="organisation" class="addUserLabel">Organisation<b>*</b></label> <br>
                <input type="text" class="form-control addUserInput" [(ngModel)]="userData.organisation"> <br>
                <label for="email" class="addUserLabel">Email Id<b>*</b></label><br>
                <input type="email" class="form-control addUserInput" [(ngModel)]="userData.email"
                    [disabled]="addEditUser == 'editUser'"> <br>
                <label for="contact" class="addUserLabel">Phone Number</label><br>
                <input type="number" class="form-control addUserInput" [(ngModel)]="userData.contact"> <br>
            </div>
            <div class="grid-item">
                <label for="last_name" class="addUserLabel">Last Name<b>*</b></label> <br>
                <input type="text" class="form-control addUserInput" (keydown)="onKeyDown('last',$event)" [(ngModel)]="userData.last_name" (input)="checkDataLength(userData.last_name,'last')"> 
                
                <span class="err_ele_span" *ngIf="lastNameLimitErr">
                    <img src="../../../assets/error_icon.svg" alt="">
                    <span>Only 15 characters are allowed.</span>
                </span>

                <br>
                <label for="user_type" class="addUserLabel">Organisation Type<b>*</b></label><br>
                <div class="custom-select-wrapper custom-select-container addUserInput">
                    <select class="form-control addUserInput dropdownArrow" [(ngModel)]="selectedOrgType">
                        <option value="" disabled>Select organisation type</option>
                        <option *ngFor="let role of org_type | keyvalue" [value]="role.value">{{ role.value }}</option>
                    </select>
                </div>

                <br><br>
                <label for="user_type" class="addUserLabel">User Type<b>*</b></label><br>
                <div class="custom-select-wrapper custom-select-container addUserInput">
                    <select class="form-control addUserInput dropdownArrow" (change)="onSelectChange($event)"
                        [(ngModel)]="selectedRole">
                        <option value="" disabled>Select user type</option>
                        <option *ngFor="let role of roles | keyvalue" [value]="role.key">{{ role.value }}</option>
                    </select>
                </div>
                
                <br><br>
                <label for="password" class="addUserLabel">Password<b *ngIf="addEditUser == 'addUser'">*</b></label><br>
                <input type="text" class="form-control addUserInput" [(ngModel)]="userData.password"
                    [value]="addEditUser === 'addUser' ? '' : null" placeholder="XXXXXX">
            </div>
            <div class="grid-item-bio">
                <label for="bio" class="addUserLabel">Brief/ Comments</label><br>
                <textarea type="text" class="form-control addUserInput square-input"
                    [(ngModel)]="userData.bio"></textarea> <br>
            </div>
        </div>
    </div>
    <div style="display: flex; gap: 20px;">
        <button style="min-width: 10%; max-width: 35%; border: none; background: none; padding: 0;"
            (click)="addNewUser()">
            <img *ngIf="addEditUser == 'addUser'" src="../../../../assets/addUser.svg" alt="add user">
            <img *ngIf="addEditUser == 'editUser'" src="../../../../assets/editUser.svg" alt="edit user">
        </button>
        <button style="min-width: 10%; max-width: 35%; border: none; background: none; padding: 0;"
            (click)="addNewUser()">
        </button>
    </div>
</div>