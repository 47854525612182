<app-header [adminFlag]="true"></app-header>
<div style="height: 90vh; background-color:#F8F8F8">
    <div style="display: flex;justify-content: space-between;margin-right: 50px; align-items: center; padding: 25px 0;">

        <div style="display: flex;align-items: center;margin-left: 53px;">
            <img (click)="navigateBack()" src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
                style="width: 27px;cursor: pointer;" />
            <span style="font-size: 20px; font-weight: 600;">USER LIST</span>

            
        </div>


        <div style="display: flex; gap: 10px;">
            <div class="filter_tag_main_div">
                <input type="checkbox" class="btn-check custom_bt_inp" name="options-tag" id="digital_inactive"
                    autocomplete="off" (change)="handleTagFilter($event,'digital_inactive')">
                <label class="btn btn-outline-success custom_bt_label" for="digital_inactive">Digital Inactive</label>

                <input type="checkbox" class="btn-check custom_bt_inp" name="options-tag" id="physical_inactive"
                    autocomplete="off" (change)="handleTagFilter($event,'physical_inactive')">
                <label class="btn btn-outline-success custom_bt_label" for="physical_inactive">Physical Inactive</label>

                <input type="checkbox" class="btn-check custom_bt_inp" name="options-tag" id="inactive"
                    autocomplete="off" (change)="handleTagFilter($event,'inactive')">
                <label class="btn btn-outline-success custom_bt_label" for="inactive">Inactive</label>

                <input type="checkbox" class="btn-check custom_bt_inp" name="options-tag" id="suspended"
                    autocomplete="off" (change)="handleTagFilter($event,'suspended')">
                <label class="btn btn-outline-success custom_bt_label" for="suspended">Suspended</label>

                <select class="form-select" aria-label="Default select example" style="max-width: 157px;font-size: 13px;" (change)="handleOrgTypeFilter($event)">
                    <option selected disabled *ngIf="!orgTypeSelctedFlag">Org. Type</option>
                    <option value="All" *ngIf="orgTypeSelctedFlag">All</option>
                    <option value="Independent Cons">Independent Cons</option>
                    <option value="Enterprise">Enterprise</option>
                    <option value="FLARE">FLARE</option>
                    <option value="Others">Others</option>
                  </select>
            </div>

            <div class="searchBox_main_div">
                <img src="../../../../assets/img/serach_grey_icon.svg" alt="">
                <input placeholder="Search..." class="searchBox" type="text" (keyup)="filter()"
                    [(ngModel)]="filterTxt" />
            </div>
            <button class="add_new_user_btn" (click)="changeCompRoute('addUser')">
                <span>Add New User</span>
                <img src="../../../../assets/img/formkit_add.svg" alt="" style="margin-left: 10px;">
            </button>
        </div>
    </div>
    <div class="tableShadow" style="margin: 0 50px; justify-content:center; overflow-y: auto">
        <table>
            <thead>
                <tr>
                    <th scope="col"><span>{{''}}</span></th>
                    <th scope="col"><span>User</span></th>
                    <th scope="col"><span>Email</span></th>
                    <th scope="col"><span>User type</span></th>
                    <th scope="col"><span>Org. Type</span></th>
                    <th scope="col"><span>Last Login</span></th>
                    <th scope="col"><span>Options</span></th>
                </tr>
            </thead>
            <tbody class="user-table-body" style="max-height: 66vh;min-height: 50vh;" appLastRowInView
                (scroll)="checkIfLastRowInView()" id="tbody_main_div">
                <tr *ngFor="let user of allUsers; let i = index" (mouseenter)="onRowHover(true, user)"
                    (mouseleave)="onRowHover(false, user)" [class.hovered]="user.reachout_flag == '1'? true : false"
                    class="user-row">
                    <td scope="row" [class.show-hand-icon]="user.reachout_flag == '1'? true : false"></td>
                    <!-- Name  -->
                    <td scope="row" class="name_label_col">
                        <span style="cursor: pointer;" (click)="openAssignment(user)">
                            {{user.first_name}}&nbsp;{{ user.last_name}}
                        </span>
                        <span class="user_label" *ngIf="user.is_suspended == '1'">SUSPENDED</span>
                        <ng-container *ngIf="user.is_suspended != '1'">
                            <span *ngIf="subcritionExpriedCheck(user, 'digitalphysical'); else checkDigital"
                                class="user_label">INACTIVE</span>
                            <ng-template #checkDigital>
                                <span *ngIf="subcritionExpriedCheck(user, 'digital'); else checkPhysical"
                                    class="user_label">DIGITAL INACTIVE</span>
                            </ng-template>
                            <ng-template #checkPhysical>
                                <span *ngIf="subcritionExpriedCheck(user, 'physical')" class="user_label">PHYSICAL
                                    INACTIVE</span>
                            </ng-template>
                        </ng-container>
                    </td>

                    <td scope="row" style="overflow-wrap: anywhere; margin-left: 20px;">{{ user.email }}</td>

                    <td scope="row" style="overflow-wrap: anywhere; margin-left: 20px;">{{ getRole(user.role_id) }}</td>

                    <td scope="row" style="margin-left: 20px;">{{ user.org_type }}</td>

                    <td scope="row" style="margin-left: 20px;">{{ getDateFormat(user.last_login_at, false) }}</td>

                    <td scope="row">
                        <div class="option_main_div">
                            <img src="../../../../assets/img/user_list_esit_icon.svg" alt="" *ngIf="user.role_id == '3'"
                                (click)="onOptionClick('assignGame', user)" [ngbTooltip]="'Edit'" tooltipClass="tooltip"
                                placement="bottom">
                            <img src="../../../../assets/img/user_list_esit_icon.svg" alt=""
                                *ngIf="user.role_id == '1' || user.role_id == '2'"
                                (click)="onOptionClick('editUser', user)" [ngbTooltip]="'Edit'" tooltipClass="tooltip"
                                placement="bottom">

                            <div class="hr_line_div" *ngIf="user.role_id != '1'">&nbsp;</div>

                            <img src="../../../../assets/img/user_list_disable_icon.svg" alt=""
                                *ngIf="user.is_suspended == '0' && user.role_id != '1'"
                                (click)="onOptionClick('suspendUser', user)" [ngbTooltip]="'Suspend'"
                                tooltipClass="tooltip" placement="bottom">

                            <img src="../../../../assets/img/user_list_enable_icon.svg" alt=""
                                *ngIf="user.is_suspended == '1' && user.role_id != '1'"
                                (click)="onOptionClick('activateUser', user)" [ngbTooltip]="'Resume'"
                                tooltipClass="tooltip" placement="bottom">

                            <div class="hr_line_div" *ngIf="user.role_id == '3'">&nbsp;</div>

                            <img src="../../../../assets/img/user_list_report_icon.svg" alt=""
                                (click)="onOptionClick('activity', user)" *ngIf="user.role_id == '3'"
                                [ngbTooltip]="'Report'" tooltipClass="tooltip" placement="bottom">
                        </div>
                        <!-- <div class="custom-dropdown" [class.open]="user.isDropdownOpen">
                            <button (click)="toggleDropdown(user)"><img
                                    src="../../../../assets/three-dots-svgrepo-com.svg" alt=""
                                    class="three_dot_svg"></button>
                            <div *ngIf="user.isDropdownOpen" class="dropdown-menu" id="testing">
                                <div *ngIf="user.role_id == '3'" (click)="onOptionClick('assignGame', user)">Assign Game
                                </div>
                                <div (click)="onOptionClick('editUser', user)">Edit User</div>
                                <div *ngIf="user.is_suspended == '0' && user.role_id != '1'"
                                    (click)="onOptionClick('suspendUser', user)">Suspend User</div>
                                <div *ngIf="user.is_suspended == '1' && user.role_id != '1'"
                                    (click)="onOptionClick('activateUser', user)">Activate User</div>
                                <div *ngIf="user.role_id == '3'" (click)="onOptionClick('activity', user)">Activity
                                </div>

                            </div>
                        </div> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>