<div>
    <div>
        <!-- <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div> -->
        <div class="wh_header_main_div" *ngIf="timerFlag">
            <div>
                &nbsp;
            </div>
            <div class="wh_header_right_sub">
                <div>
                    <span>Time left to complete the set-up </span>
                    <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
                </div>
            </div>
        </div>
        <div class="main-container">
            <div class="left-div">
                <div>
                    <div class="f-w-500">Image</div>
                    <div>Image should be <span class="f-w-500">16 : 9</span> ratio and less than <span
                            class="f-w-500">8MB</span>.</div>
                    <div class="m-b-10">Ideal image upload size is <span class="f-w-500">1920x1080


                        </span>.</div>
                </div>
                <div class="left-img-config-div">
                    <div (click)="fileInput_image.click()" class="img-click-div">
                        <input type="file" accept="image/png,image/jpeg" (change)="onImageChange($event)"
                            #fileInput_image hidden id="hiddenImgInput" />
                        <div *ngIf="!uploadedSrc && !uploadedFlag">
                            <img src="../../../assets/uplaoded_icon_bp.svg" alt="" style="margin-bottom: 5px;">
                        </div>
                        <div *ngIf="!uploadedSrc && !uploadedFlag">
                            Choose file to upload
                        </div>
                        <div *ngIf="uploadedSrc && uploadedFlag">
                            <img src="../../../assets/reuploaded_icon_bp.svg" alt="" style="margin-bottom: 5px;">
                        </div>
                        <div *ngIf="uploadedSrc && uploadedFlag">
                            Choose another file to upload <br /> (Existing image will be deleted)
                        </div>
                        <div *ngIf="uploadedSrc && !uploadedFlag">
                            <img [src]="uploadedSrc" alt="" style="width: 70px; height: 39.4px;">
                        </div>
                        <div *ngIf="uploadedSrc && !uploadedFlag">
                            {{imgName}}
                        </div>
                    </div>
                    <div>
                        <button class="img-upload-btn" (click)="upload()">Upload</button>
                    </div>
                </div>
                <div>
                    <div class="f-w-500 m-t-b-10">Jig-Saw Pieces</div>
                    <div class="row-col-config-main-div">
                        <div class="row-config-div">
                            <div>Rows</div>
                            <div><input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                    [(ngModel)]="rows" (change)="handleRowColoumChange()"></div>
                        </div>
                        <div>
                            <div>&nbsp;</div>
                            <div class="config-inbtween-text">X</div>
                        </div>
                        <div class="row-config-div">
                            <div>Columns</div>
                            <div><input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                    [(ngModel)]="coloum" (change)="handleRowColoumChange()"></div>
                        </div>
                        <div>
                            <div>&nbsp;</div>
                            <div class="config-inbtween-text">=</div>
                        </div>
                        <div class="row-config-div">
                            <div>Total Pieces</div>
                            <div>
                                <input type="number" disabled [(ngModel)]="totalPiece" min="1"
                                    oninput="javascript: if(this.value == 0) this.value=''">
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-w-500 m-t-b-10">Game Codes</div>
                    <div class="game-code-main-div m-b-10">
                        <div>Total number of game codes</div>
                        <div>
                            <select name="noOfRound" id="" class="form-select custom_select" [(ngModel)]="noOfGameCode"
                                (change)="handleNoOfRound($event.target)">
                                <!-- <option value="" disabled>Select no of codes</option> -->
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="70">70</option>
                                <option value="100">100</option>
                            </select>
                            <!-- <input type="number" [(ngModel)]="noOfGameCode" min="1"
                                oninput="javascript: if(this.value == 0) this.value=''"> -->
                        </div>
                    </div>
                </div>

            </div>
            <div class="right-div">
                <div>&nbsp;</div>
                <div class="f-w-500 m-b-10">Image Preview</div>
                <div class="img-privew-div">
                    <div class="img-privew-sub-div">
                        <div *ngIf="!previewSrc" class="blank-img-div"></div>
                        <div *ngIf="previewSrc" class="filled-img-div">
                            <img [src]="previewSrc" alt="" class="preview-img">
                        </div>
                    </div>
                </div>
                <div>
                    <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                        <input style="width: 27%; max-width: 200px" type="image"
                            src="../../../assets/img/save&conti-btn.svg" (click)="saveBigPictureConfig()"
                            *ngIf="totalPiece  && noOfGameCode && previewSrc ; else disabled_btn_bp" />
                        <ng-template #disabled_btn_bp>
                            <input style="width: 27%; max-width: 200px; cursor: not-allowed;" type="image"
                                src="../../../assets/save&countinue_disabled.svg" />
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>