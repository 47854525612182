<div class="wh_component_div">
    <div style="flex: 1;">
        <!-- <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div> -->
        <div class="wh_header_main_div">
            <div>
                <div style="font-weight: 600;font-size: 22px;">Game set-up</div>
                <div>Please review the game details before you finish the setup !</div>
            </div>
            <div class="wh_header_right_sub">
                <div *ngIf="timerFlag">
                    <span>Time left to complete the set-up </span>
                    <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
                </div>
                <div>
                    <img src="../../../assets/profile.png" alt="" (click)="toggleFunc()" style="cursor: pointer;">
                </div>
            </div>
            <div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
                <div class="header-drowdwon-content" (click)="goToAccountSetting()">
                    My Profile
                </div>
                <div class="header-drowdwon-content" (click)="goToMyGame()">
                    My Games
                </div>
                <div class="header-drowdwon-content" (click)="goGameReport()">
                    Games Reports
                </div>
                <div class="header-drowdwon-content" (click)="goToTCPage()">
                    Terms and conditions
                </div>
                <div class="header-drowdwon-content" (click)="logout()">
                    Logout
                </div>
            </div>
        </div>
        <div class="config_main_div">
            <div class="config_header_div">
                <div><img src="../../../assets/great_ocean_kingdom_logo.svg" alt="" style="width: 4rem;"></div>
            </div>
            <div class="config_body_main_div">
                <div class="config_left_main_div">
                    <div class="ele_main_div" style="align-items: center;">
                        <span>Client Logo
                            <!-- <img placement="bottom" [ngbTooltip]="noOfPlayersTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>:</span>
                        <input type="file" accept="image/png,image/jpeg" (change)="onImageChange($event)"
                            #fileInput_image hidden id="hiddenImgInput" />
                        <span class="pl_10" *ngIf="logoFileSrc == ''">
                            <div>
                                <div class="img-click-div" (click)="fileInput_image.click()">
                                    <img src="../../../assets/uplaoded_icon_bp.svg" alt="" style="margin-bottom: 5px;">
                                    <span>Choose file to upload</span>
                                </div>
                            </div>
                        </span>
                        <div *ngIf="logoFileSrc != ''" class="logo_preview_main_div">
                            <div><img [src]="logoFileSrc" alt="" class="logo_preview_img"></div>
                            <div class="img-click-div" (click)="fileInput_image.click()">
                                <img src="../../../assets/uplaoded_icon_bp.svg" alt="" style="margin-bottom: 5px;">
                                <span>Replace logo</span>
                            </div>
                        </div>
                    </div>
                    <div class="ele_main_div">
                        <span>No of players
                            <!-- <img placement="bottom" [ngbTooltip]="noOfPlayersTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>:</span>
                        <span class="pl_10">{{maxPlayerData}}</span>
                    </div>
                    <div class="ele_main_div">
                        <span>Player in a teams
                            <!-- <img placement="bottom" [ngbTooltip]="playerInTeamsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>:</span>
                        <span style="display: flex;align-items: flex-start;gap: 10px;flex-direction: column;">
                            <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                [(ngModel)]="noOfTeamsData" class="ele_input" (change)="fillNoPlyr()"
                                (keydown)="validateNumber($event)" [ngClass]="noOfTeamsData > 10 || noOfTeamsData > maxPlayerData?'colorRedCss':''">
                            <span class="err_ele_span" *ngIf="noOfTeamsData > maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Players in a team can not be more than no of players</span>
                            </span>
                            <span class="err_ele_span" *ngIf="noOfTeamsData > 10 && noOfTeamsData <= maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Players in a team can not be more than 10</span>
                            </span>
                            <!-- <span class="err_ele_span" *ngIf="perTeamPlyData > 20 && noOfTeamsData < 10">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>No of team can not be more than 20</span>
                            </span> -->
                        </span>
                    </div>
                    <div class="ele_main_div">
                        <span>Number of teams
                            <!-- <img placement="bottom" [ngbTooltip]="noOfTeamsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>:</span>
                        <span class="pl_10">{{perTeamPlyData}}</span>
                    </div>
                    <div class="ele_main_div">
                        <span>Game time (Mins)
                            <!-- <img placement="bottom" [ngbTooltip]="gameTimeTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>&nbsp;</span>
                        <span>
                            <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                [(ngModel)]="gameTimeData" class="ele_input" (keydown)="validateNumber($event)"
                                [ngClass]="gameTimeData >= maxDurationBySlots?'colorRedCss':''">
                            <span class="err_ele_span" style="margin-top: 10px;"
                                *ngIf="gameTimeData >= maxDurationBySlots">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Game time should be less than session duration</span>
                            </span>
                        </span>
                    </div> 
                </div>
                <div class="config_right_main_div">
                    <div class="config_right_sub_div">
                        <div>
                            <span class="config_right_heading">Challenge  #7</span>
                            <!-- <img src="../../../assets/img/tooltip.svg" style="cursor: pointer;"
                                (click)="showSideOrder = true"> -->
                        </div>
                        <div class="gor_challenge_name">
                            <li>The Sailors' Treasure</li>
                            <li>The MoviEmoji Challenge</li>
                            <li>Guess the Personalities</li>
                            <li>DragDrop the sea creatures</li>
                            <li>The Great Ocean Crossword Puzzle</li>
                            <li>The Number Puzzles</li>
                            <li>Rebus Riddles</li>
                            <!-- <div class="grid-3" *ngFor="let item of noOfRoundEmptyArray;let i = index">
                                <div>Round {{i+1}} :</div>
                                <div>
                                    <select name="" id="" class="form-select round-select"
                                        [(ngModel)]="roundData[i]['value']"
                                        (change)="handleLevelChange($event.target,i)">
                                        <option value="Simple1" selected>Simple | Order 1</option>
                                        <option value="Simple2" selected>Simple | Order 2</option>
                                        <option value="Advanced1">Advanced | Order 1</option>
                                        <option value="Advanced2">Advanced | Order 2</option>
                                    </select>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                <input style="width: 27%; max-width: 200px" type="image" src="../../../assets/img/save&conti-btn.svg"
                    (click)="saveGORConfig()" *ngIf="!((noOfTeamsData > maxPlayerData) ||
                    (noOfTeamsData > 10 && noOfTeamsData <= maxPlayerData)) && noOfTeamsData && !(gameTimeData >= maxDurationBySlots) && gameTimeData; else disabled_btn_gor">

                    <ng-template #disabled_btn_gor>
                        <input style="width: 27%; max-width: 200px; cursor: not-allowed;" type="image" src="../../../assets/save&countinue_disabled.svg" />
                    </ng-template>
                    
            </div>
        </div>

    </div>
</div>


























<style>
    .step-main-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 205px;
    }

    .step-main-div div {
        padding: 10px 30px;
        font-size: 18px;
        background-color: #e4e4e4;
    }

    .step-main-div div:first-of-type {
        border-bottom-left-radius: 15px;
    }

    .step-main-div div:last-of-type {
        border-bottom-right-radius: 15px;
    }

    .step-active-css {
        border-top: 3px solid #30E593;
        background-color: #ffffff !important;
    }

    .disabledInput {
        background-color: #dddddd !important;
    }

    .header-drowdwon {
        position: absolute;
        right: 2px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 11px 11px;
        opacity: 1;
        border-top: none;
        z-index: 1111;
        box-shadow: 0px 3px 6px #00000029;
        top: 83%;
    }

    .header-drowdwon-content {
        padding: 5px 15px;
        cursor: pointer;
    }

    .header-drowdwon-content:hover {
        background-color: #C6E8C9;
    }

    .header-drowdwon-content:last-of-type:hover {
        border-radius: 0px 0px 11px 11px;
    }

    .drop_down_type_main_div {
        position: absolute;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        width: 18vw;
    }

    .theame_drop_down_ele_div {
        display: flex;
        height: 37px;
    }

    .theame_drop_down_ele_div_wrap {
        position: relative;
        min-width: 18vw;
        padding: 5px;
        cursor: pointer;
    }

    .theame_drop_down_ele_div_sub_wrap {
        margin-left: 12px;
    }


    .hover_main_div {
        padding: 8px;
        width: 18vw;
        text-align: center;
        background: #ADC4FD 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        height: 37px;
    }

    .hover_sub_div {
        display: flex;
        gap: 20px;
    }

    .hover_sub_div img {
        width: 22px;
        height: 22px;
    }

    .theameSelectedCss {
        background: #c8d6f7 0% 0% no-repeat padding-box;
    }
</style>