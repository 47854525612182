<app-header [oneHeader]=header [adminFlag]="true"></app-header>
<div style="height: 90vh; background-color:#F8F8F8" class="main_game_assign_div">
    <div class="main_game_assign_item" style="min-width: 400px; overflow-y: auto;">
        <div class="user_name_main_div">
            <div>
                <img (click)="navigateBack()" src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
                    style="width: 36px;margin-right: 10px;cursor: pointer;" />
                {{user_dtls.first_name}}&nbsp;{{user_dtls.last_name}}
            </div>
            <div (click)="editUserProfile()">Edit</div>
        </div>
        <div class="white_card" *ngIf="user_dtls.bio">
            {{user_dtls.bio}}
        </div>
        <div class="white_card">
            <div class="user_info_div">
                <span>User Type :</span>
                <span>{{getRole(user_dtls.role_id)}}</span>
            </div>
            <div class="user_info_div">
                <span>Organisation :</span>
                <span>{{user_dtls.organisation}}</span>
            </div>
            <div class="user_info_div">
                <span>Org Type :</span>
                <span>{{user_dtls.org_type}}</span>
            </div>
            <div class="user_info_div">
                <span>Email Id :</span>
                <span>{{user_dtls.email}}</span>
            </div>
            <div class="user_info_div">
                <span>Password :</span>
                <span>* * * * * * * *</span>
            </div>
            <div class="user_info_div">
                <span>Phone No. :</span>
                <span>{{user_dtls.contact}}</span>
            </div>
            <div class="user_info_div">
                <span>Registered on :</span>
                <span>{{user_dtls.registered_on}}</span>
            </div>
            <!-- <div class="user_info_div">
                <span>User name :</span>
                <span>{{user_dtls.email}}</span>
            </div> -->
            
            
        </div>
        <div class="white_card reachout_main_div" style="flex: 1; overflow-y: auto;min-height: 10rem;">
            <button class="toggle_right_btn" (click)="changeRightCard('Assign Game')"
                [ngClass]="{'active_card_btn': selectedRightCard == 'Assign Game'}">
                Assign Game
            </button>


            <button class="toggle_right_btn" (click)="changeRightCard('Subscription Details')"
                [ngClass]="{'active_card_btn': selectedRightCard == 'Subscription Details'}">
                Subscription Details
            </button>


            <button class="toggle_right_btn" (click)="changeRightCard('Reach Out')"
                [ngClass]="{'active_card_btn': selectedRightCard == 'Reach Out'}">
                Reach Out
            </button>
        </div>
    </div>
    <div style="width: 100%;display: flex;flex-direction: column; margin-top: 22px;">
        <div style="height: 6%;">
            <div
                style="height: 100%;background-color: #ABEADA;display: flex;justify-content: center;align-items: center;">
                <div class="right_card_heading">{{selectedRightCard}}</div>
            </div>
        </div>
        <div style="display: flex;flex: 1;height: 94%;gap: 3vw;"
            *ngIf="selectedRightCard == 'Assign Game' || selectedRightCard == 'Subscription Details'">
            <div>
                <div class="leftContainer">
                    <div class="individualSelections" [ngClass]="{highlightdigital:selectedExplore=='digital'} "
                        (click)="selectedExploreGame('digital')">
                        <img src="../../../assets/img/digitalGame.svg"
                            [ngClass]="{rounded:selectedExplore=='digital',circle:selectedExplore!='digital'} ">
                        <b>Digital Games</b>
                        <i class="arrow right arrow-digital" *ngIf="selectedExplore=='digital'"></i>
                    </div>
                    <div class="individualSelections" [ngClass]="{highlightphysical:selectedExplore=='physical'} "
                        (click)="selectedExploreGame('physical')">
                        <img src="../../../assets/img/physicalGame.svg"
                            [ngClass]="{rounded:selectedExplore=='physical',circle:selectedExplore!='physical'} ">
                        <b>Physical Games</b>
                        <i class="arrow right arrow-physical" *ngIf="selectedExplore=='physical'"></i>
                    </div>
                </div>
            </div>


            <!------------------------ Assignment HTML --------------------- -->
            <!------------------------ Assignment HTML --------------------- -->
            <!------------------------ Assignment HTML --------------------- -->
            <div style="width: 100%;" class="digital_main_assignment_div" *ngIf="selectedRightCard == 'Assign Game'">
                <!-- Digital  -->
                <!-- Digital  -->
                <div *ngIf="selectedExplore == 'digital'" style="width: 100%; padding-top: 20px;">
                    <div class="physical_game_main_div" *ngIf="!di_assign_flag">
                        <div class="phsical_heading">Digital Games</div>
                        <div class="physical_body_main_div" style="justify-content: space-around;align-items:center;">
                            <div class="digital_game_info_div"
                                *ngIf="user_digital_subcription.length > 0 && license_left > 0 && user_digital_subcription_active_flag">
                                <div>
                                    <div class="assign_heading">No. of Licenses</div>
                                    <div style="margin: 10px 0;">#Left/ Active: {{license_left }}</div>
                                    <div>#Total licenses purchased: : {{licence_total}}</div>
                                </div>
                                <div>
                                    <div class="assign_heading">Validity</div>
                                    <div style="margin: 10px 0;">Licenses & Game resources valid till:
                                        {{!checkDigitalGame?
                                        (user_digital_subcription[0].end_date*1000 | date:'dd/MM/yyyy') :
                                        (new_user_digital_subcription[0].end_date*1000 | date:'dd/MM/yyyy')}}</div>
                                    
                                </div>
                            </div>
                            <div class="white_card" style="width: 80%;"
                                *ngIf="user_digital_subcription.length > 0 && license_left == 0 && user_digital_subcription_active_flag">
                                <div class="py_not_found" style="border-bottom: 1px solid;">
                                    <h3>This account does not have any licenses</h3>
                                </div>
                                <div>
                                    <div style="text-align: center;font-size: 19px;margin-top: 18px;color: #1673A7;">
                                        Active Subscription
                                    </div>
                                    <div class="last_subscription_dtls">
                                        <div>
                                            <div class="assign_heading">No. of Licenses</div>
                                            <div style="margin: 10px 0;">#Left/ Active: {{license_left }}</div>
                                            <div>#Total licenses purchased: {{licence_total}}</div>
                                        </div>
                                        <div>
                                            <div class="assign_heading">Validity</div>
                                            <div style="margin: 10px 0;">Licenses & Game resources valid till:
                                                {{!checkDigitalGame?
                                                (user_digital_subcription[0].end_date*1000 | date:'dd/MM/yyyy') :
                                                (new_user_digital_subcription[0].end_date*1000 | date:'dd/MM/yyyy')}}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="subscription_dtls_main_card"
                                *ngIf="user_digital_subcription.length == 0 || !user_digital_subcription_active_flag">
                                <div class="white_card" style="min-width: 300px;">
                                    <div class="py_not_found">
                                        <h3>This account does not have any games</h3>
                                    </div>
                                    <div style="background-color: #c5c5c5 !important;border-radius: 10px;"
                                        *ngIf="user_digital_subcription.length > 0">
                                        <div style="text-align: center;color: red;font-size: 19px;">Last Subscription
                                        </div>
                                        <div class="last_subscription_dtls">
                                            <div>
                                                <div class="assign_heading">No. of Licenses</div>
                                                <div style="margin: 10px 0;">#Left/ Active: {{license_left }}</div>
                                                <div>#Total licenses purchased: {{licence_total}}</div>
                                            </div>
                                            <div>
                                                <div class="assign_heading">Validity</div>
                                                <div style="margin: 10px 0;">Licenses & Game resources valid till:
                                                    {{!checkDigitalGame?
                                                    (user_digital_subcription[0].end_date*1000 | date:'dd/MM/yyyy') :
                                                    (new_user_digital_subcription[0].end_date*1000 |
                                                    date:'dd/MM/yyyy')}}
                                                </div>
                                                <!-- <div>Purchased on : {{ !checkDigitalGame?
                                                    (user_digital_subcription[0].start_date*1000 |
                                                    date:'dd/MM/yyyy') :
                                                    (new_user_digital_subcription[0].start_date*1000 |
                                                    date:'dd/MM/yyyy')}}</div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="assign_new_btn_div" style="margin: 3rem 0;">
                                    <button class="assign_new_btn" (click)="assignNewGame('digital')">ASSIGN NEW GAMES</button>
                                </div>
                            </div>
                            <div class="assign_new_btn_div" style="margin: 3rem 0;"
                                *ngIf="user_digital_subcription.length > 0 && license_left == 0 && user_digital_subcription_active_flag">
                                <button class="assign_new_btn" (click)="assignNewGame('digital')">ADD LICENSES</button>
                            </div>
                            <div class="assign_new_btn_div" style="margin: 3rem 0;"
                                *ngIf="user_digital_subcription.length > 0 && license_left > 0 && user_digital_subcription_active_flag">
                                <button class="assign_new_btn" (click)="assignNewGame('digital',1)">UPDATE LICENSES</button>
                            </div>
                            <div class="digital_games" style="width: 90%;"
                                *ngIf="user_digital_subcription.length > 0 && user_digital_subcription_active_flag">
                                <div class="py_game_aceess_main_div"
                                    *ngFor="let item of unique_user_digital_subcription">
                                    <img *ngIf="item.game_id==1" src="../../../../assets/img/valuecontinuum.svg" />
                                    <img *ngIf="item.game_id==2" src="../../../../assets/img/explore_bn.svg" />
                                    <img *ngIf="item.game_id==3" src="../../../../assets/img/explore_wh.svg" />
                                    <img *ngIf="item.game_id==4"
                                        src="../../../../assets/img/Picture_Perfect_Logo.png" />
                                    <img *ngIf="item.game_id==5" src="../../../../assets/img/animalFarm-logo.png" />
                                    <img *ngIf="item.game_id==6" src="../../../../assets/img/bigPicture.svg" />
                                    <img *ngIf="item.game_id==14"
                                        src="../../../../assets/great_ocean_kingdom_logo.svg" />
                                    <img *ngIf="item.game_id==15" src="../../../../assets/people_of_world_logo.svg" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <digital-game-assignment *ngIf="di_assign_flag" [gameAccess]="user_digital_subcription"
                        [user_id]="user_id" [licence_total]="licence_total" [licence_left]="license_left"
                        [user_digital_subcription]="!checkDigitalGame ? user_digital_subcription : new_user_digital_subcription"
                        [addOrUpdate]="addOrUpdate" (backEmitter)="receiveMessage($event)"></digital-game-assignment>
                </div>

                <!-- Physical  -->
                <!-- Physical  -->
                <div *ngIf="selectedExplore == 'physical'" style="height: 100%; padding-top: 20px;">
                    <div class="physical_game_main_div" *ngIf="!py_assign_flag">
                        <div class="phsical_heading">Physical Games</div>
                        <div class="physical_body_main_div" style="justify-content: flex-start;">
                            <div *ngIf="user_physical_subcription.length == 0">
                                <div class="white_card" style="min-width: 300px;margin-top:2rem;width: 90%;">
                                    <div class="py_not_found">
                                        <h3>This account does not have any games</h3>
                                    </div>
                                </div>
                                <div class="assign_new_btn_div" style="display:flex;justify-content: center;">
                                    <div class="assign_new_btn" (click)="assignNewGame('physical')" style="cursor: pointer;">
                                        ASSIGN NEW GAMES
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="user_physical_subcription.length > 0">
                                <div class="physicalGameTable " style="min-width: 300px;width:97%;">
                                    <div class="">
                                        <div class="py_subcription_dtls_main_div">
                                            <div>
                                                <div class="assign_heading">Game Kits</div>
                                                <div style="margin: 10px 0;">#Games:
                                                    {{user_active_physical_subcription.length}}</div>
                                                <div style="margin: 10px 0;">#Total kits purchased: {{totalGameKit}}
                                                </div>
                                            </div>

                                            <div class="" (appOutsideClick)="outSideValidtyBoxClickHandle($event)"
                                                *ngIf="!noActiveGameFlag">
                                                <div class="assign_heading">Validity</div>
                                                <div class="pyGameValidty_sub_div">
                                                    <div style="margin: 10px 0;">
                                                        Game resources valid till:
                                                    </div>
                                                    <div *ngIf="!validityUpdateFlag" class="pyGameValidty_date"
                                                        style="margin: 10px 0;">
                                                        {{getDate(user_physical_subcription[0].end_date)|
                                                        date:'dd/MM/yyyy'}}
                                                    </div>
                                                    <div *ngIf="validityUpdateFlag"
                                                        style="margin: 10px 0;max-width: 47%;">
                                                        <!-- <input type="date" id="datePicker" class="date_inp dg_inp"
                                                            [(ngModel)]="updateDate"
                                                            (change)="changeDate($event, 'date') "
                                                            [attr.min]="minEndDate" /> -->

                                                        <mat-form-field appearance="fill"
                                                            [ngStyle]="{'max-width': '163px','background-color': 'transparent'}">
                                                            <input matInput [matDatepicker]="picker"
                                                                [(ngModel)]="updateDate"
                                                                (dateChange)="changeDate($event, 'date')"
                                                                [min]="minEndDate"
                                                                [ngStyle]="{'background-color': 'transparent'}">
                                                            <mat-datepicker-toggle matSuffix
                                                                [for]="picker"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                                <div style="text-align: center;">
                                                    <button class="pyGameValidty_cahnge_btn" (click)="changeValidty()"
                                                        *ngIf="!validityUpdateFlag">EXTEND RESOURCES</button>
                                                    <button class="pyGameValidty_cahnge_btn pyGameValidty_update_btn"
                                                        (click)="updateValidity()"
                                                        *ngIf="validityUpdateFlag">UPDATE</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="gridPhysicalGames">
                                            <div style="min-width: 10rem;">
                                                Assigned Games
                                            </div>

                                            <!-- <div style="align-self: center;text-align: center;justify-self: center;">
                                                Kits
                                            </div> -->
                                            <div>

                                            </div>
                                        </div>



                                        <div class="assign_py_card_main_div">

                                            <div class="physicalGameUser" style="padding: 10px 10px;"
                                                *ngFor="let item of user_physical_subcription"
                                                [ngClass]="{'expired_game_disable': item.is_active == 0}">
                                                <div class="assign_py_my_game_img_main_div">
                                                    <img src="../../../assets/img/myGames/human_matrix.svg" alt=""
                                                        *ngIf="item.game_id == 8">
                                                    <img src="../../../assets/img/string_therory.png" alt=""
                                                        *ngIf="item.game_id == 9">
                                                    <img src="../../../assets/img/strom_selter.png" alt=""
                                                        *ngIf="item.game_id == 10">
                                                    <img src="../../../assets/img/assebembly_line.png" alt=""
                                                        *ngIf="item.game_id == 11">
                                                    <img src="../../../assets/img/magic_mats.png" alt=""
                                                        *ngIf="item.game_id == 12">
                                                    <img src="../../../assets/img/stranded.png" alt=""
                                                        *ngIf="item.game_id == 13">
                                                    <img src="../../../assets/friction_action_game.png" alt=""
                                                        *ngIf="item.game_id == 16">
                                                    <img src="../../../assets/lean_up_game.png" alt=""
                                                        *ngIf="item.game_id == 17">
                                                    <img src="../../../assets/traficjam_game.png" alt=""
                                                        *ngIf="item.game_id == 18">
                                                    <img src="../../../assets/vc_game_py.svg" alt=""
                                                        *ngIf="item.game_id == 19">
                                                </div>
                                                <div class="assign_game_kit_main_div">
                                                    <div class="assign_game_kit_sub_div">
                                                        <img src="../../../assets/img/myGames/game_kit_icon.svg" alt=""
                                                            style="width: 36px;">
                                                        <div class="assign_game_kit_info">
                                                            <div *ngIf="(selectedId != item.game_id)">{{item.game_kits}}
                                                            </div>
                                                            <ng-container
                                                                *ngIf="!newKitDateNextFlag && selectedId == item.game_id && updateCheck">
                                                                <div class="addKit_container">
                                                                    <div style="text-align: right;">
                                                                        <img src="../../../../assets/close-circle-svgrepo-com.svg"
                                                                            alt="" style="width: 25px; cursor: pointer;"
                                                                            (click)="closeGameKitEdit()">
                                                                    </div>


                                                                    <div style="margin-bottom: 25px;">
                                                                        <label for="kit" class="game_kit_label">No. of
                                                                            kit</label>
                                                                        <input type="number"
                                                                            style="text-align: center; height: 33px;border-radius: 6px;border-width: 1px;border-color: gray;max-width: 92%; font-size: 13px;"
                                                                            (keydown)="onKeyDown($event)"
                                                                            [(ngModel)]="updateKits"
                                                                            placeholder="Enter no of kit" />
                                                                    </div>

                                                                    <label for="date" class="game_kit_label">Game
                                                                        resources valid till</label>
                                                                    <mat-form-field appearance="fill"
                                                                        [ngStyle]="{'max-width': '92%','background-color': 'transparent','font-size': '12px'}">
                                                                        <mat-label>Select date</mat-label>
                                                                        <input matInput [matDatepicker]="picker"
                                                                            [(ngModel)]="newKitDate"
                                                                            (dateChange)="changeDate($event, 'gamekit')"
                                                                            [min]="minEndDate"
                                                                            [ngStyle]="{'background-color': 'transparent'}">
                                                                        <mat-datepicker-toggle matSuffix
                                                                            [for]="picker"></mat-datepicker-toggle>
                                                                        <mat-datepicker #picker></mat-datepicker>
                                                                    </mat-form-field>
                                                                    <div class="add_kit_main_div">
                                                                        <button class="update_kit_btn" (click)="submit(item)">Update </button>
                                                                    </div>
                                                                    
                                                                </div>

                                                                
                                                            </ng-container>




                                                            <div>Game Kits</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="add_kit_main_div">
                                                    <button class="add_kit_btn" *ngIf="selectedId != item.game_id"
                                                        (click)="updateGame(item)">ADD KITS</button>
                                                </div>













                                            </div>
                                        </div>
                                    </div>
                                    <div class="assign_new_btn_div">
                                        
                                        <button class="assign_new_btn" (click)="assignNewGame('physical')">
                                            {{user_physical_subcription.length > 0 ? 'ADD NEW GAMES' : 'ASSIGN NEW GAMES'}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <physical-game-assignment *ngIf="py_assign_flag" [gameAccess]="user_active_physical_subcription"
                        [user_id]="user_id" [addOrUpdate]="addOrUpdate"
                        (backEmitter)="receiveMessage($event)"></physical-game-assignment>
                </div>







            </div>

            <!------------------------ Subscription Dtls HTML --------------------- -->
            <!------------------------ Subscription Dtls HTML --------------------- -->
            <!------------------------ Subscription Dtls HTML --------------------- -->

            <div style="width: 100%;" class="sub_table_content_main_div"
                *ngIf="selectedRightCard == 'Subscription Details'">

                <!-- Digital Game  -->
                <div *ngIf="selectedExplore == 'digital'">
                    <div style="margin-top: 2rem; margin-right: 3vw;">
                        <table class="table table-bordered table-rounded-assign">
                            <thead>
                                <tr>
                                    <th scope="col">S. No.</th>
                                    <th scope="col">Start or Purchased Date </th>
                                    <th scope="col">End or Expired Date</th>
                                    <th scope="col">Time Duration (days)</th>
                                    <th scope="col">Licenses Purchased</th>
                                    <th scope="col">Licenses Extended</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="digitalSubscriptionHistory.length > 0;else diNoDataFound">
                                    <ng-container *ngFor="let item of digitalSubscriptionHistory;let diInx = index">
                                        <tr>
                                            <th scope="row">{{diInx+1}}</th>
                                            <td>{{convertEpochToDate(item.start_date)}}</td>
                                            <td>{{convertEpochToDate(item.end_date)}}</td>
                                            <td>{{differenceInDays(item.end_date,item.start_date)}}</td>
                                            <td>{{item.no_of_licenses}}</td>
                                            <td>{{item.carried_license ? item.carried_license : 0}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-template #diNoDataFound>
                                    <tr>
                                        <th scope="row" colspan="6"
                                            style="border-right-width: 2px;border-bottom-right-radius: 4px;text-align: center;">
                                            No Data Found</th>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Physical Game -->
                <div *ngIf="selectedExplore == 'physical'">
                    <div style="margin-top: 2rem; margin-right: 3vw;">
                        <table class="table table-bordered table-rounded-assign">
                            <thead>
                                <tr>
                                    <th scope="col">S. No.</th>
                                    <th scope="col"> Game Name</th>
                                    <th scope="col">Start or Purchased Date</th>
                                    <th scope="col">Time duration (Months & Days)</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Number of Kits</th>
                                    <!-- <th scope="col">#Resources Extended (days)</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="physicalSubscriptionHistory.length > 0;else pyNoDataFound">
                                    <ng-container *ngFor="let item of physicalSubscriptionHistory;let pyInx = index">
                                        <tr [ngClass]="getClassname(item)">
                                            <th scope="row">{{pyInx+1}}</th>
                                            <td>{{item.game_name == null && item.is_active == 0 ? 'RESOURCES EXTENDED' : item.game_name}}</td>
                                            <td>{{convertEpochToDate(item.start_date)}}</td>
                                            <td>{{calculateMonthsAndDaysFromEpoch(item.start_date,item.validity)}}</td>
                                            <td>{{convertEpochToDate(item.end_date)}}</td>
                                            <td>{{item.game_name == null && item.is_active == 0 ? 0 : item.game_kits}}</td>
                                            <!-- <td>{{item.resource_extended ? expolodeExtenstion(item.resource_extended) :
                                                0}}</td> -->
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-template #pyNoDataFound>
                                    <tr>
                                        <th scope="row" colspan="7"
                                            style="border-right-width: 2px;border-bottom-right-radius: 4px;text-align: center;">
                                            No Data Found</th>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!------------------------ Reach Out HTML --------------------- -->
        <!------------------------ Reach Out HTML --------------------- -->
        <!------------------------ Reach Out HTML --------------------- -->
        <div style="width: 100%;" class="reach_out_main_div_wrraper" *ngIf="selectedRightCard == 'Reach Out'">
            <!-- <div class="reach_out_heading_div">
                <img src="../../../../assets/raisedHand.svg" alt=""
                    *ngIf="user_dtls.reachout_id && user_dtls.reachout_reason.length > 0">
                <div>Reach out</div>
            </div> -->
            <div class="reach_out_reason_div" *ngIf="user_dtls.reach_out.length > 0;else noReachOut">
                <div *ngFor="let item of user_dtls.reach_out;let reachout_inx = index" class="querie_ele_div">
                    <div class="reach_out_heading_querie_main_div">
                        <div class="reach_out_heading_querie_sub_div">
                            <ng-container
                                *ngFor="let rechoutHeading of item.reachout_reason;let rechoutHeadingInx = index">
                                <span *ngIf="rechoutHeadingInx > 0">
                                    |
                                </span>
                                <span>{{rechoutHeading.reason_txt}}</span>
                            </ng-container>
                        </div>
                        <div *ngIf="item.querie" class="reach_out_msg">{{item.querie}}</div>
                    </div>
                    <div class="dateTime_btn_main_div">
                        <span>{{getQueryDateTime(item.updated_at)}}</span>
                        <button (click)="resolveHandle(item,reachout_inx)">Resolve</button>
                    </div>
                </div>

            </div>
            <ng-template #noReachOut>
                <div class="no_reach_out_div">
                    <h3>No Reach Out</h3>
                </div>
            </ng-template>


        </div>
    </div>
</div>