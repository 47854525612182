<div class="mainDiv">

    <div class="success_img_main_div">
        <img src="../../../assets/success_blue_tick.svg" alt="">
    </div>

    <div class="completed_heading">Session set up completed</div>

    <div class="program_dtls_mail_div">
        <div class="program_dtls_img_div">
            <img [(src)]="sessionData.games[0].imgUrl" alt="" srcset="" class="">
        </div>
        <div class="program_dtls_sub_div">
            <div class="program_dtls_session_div">
                <span>{{sessionData.name}}</span>
                <span> | </span>
                <span>{{sessionData.clientName}}</span>
            </div>
            <div class="program_dtls_time_div">
                <span>{{sessionData.date | date:' d MMMM yyyy' }}</span>
                <span>, </span>
                <span>{{sessionData.time}}</span>
                <!-- <span> - </span>
                <span>{{convertEpochToTime(programData.slots.end_time)}}</span> -->
            </div>
            <div class="program_dtls_player_div">{{sessionData.maxPly}} Players</div>
        </div>


        
    </div>
    
    <div class="more_info_div" *ngIf="getTimeDiff(sessionData.time) > 3600">The session can be deleted till {{noDeductionTime(sessionData.time,1,1)}} for 100% licenses reversal.</div>
    <div class="more_info_div" *ngIf="getTimeDiff(sessionData.time) < 3600 && getTimeDiff(sessionData.time) > 0">The session can be deleted till {{noDeductionTime(sessionData.time,0,1)}} for 50% licenses reversal.</div>
    <div class="more_info_div" *ngIf="getTimeDiff(sessionData.time) < 0">This session cannot be deleted or edited.</div>

    <div class="term_btn_main_div">


        <div class="term_condition_main_div">
            <div class="checkbox_div" (click)="termConditionFlag = !termConditionFlag">
                <span *ngIf="!termConditionFlag">&nbsp;</span>
                <img src="../../../assets/check_box_tick.svg" alt="" *ngIf="termConditionFlag">
            </div>
            <span class="term_condition_txt">
                I agree to the <span (click)="openTearmAndCondition()">Terms & Conditions</span> of the licenses usage for the Kairos game sessions.
            </span>
        </div>


        <div class="licences_info_btn_main_div">
            <button class="go_back_btn" (click)="close(false)">No, Go Back</button>
            <button class="disabled_continue_btn" *ngIf="!termConditionFlag">YES, Continue</button>
            <button class="continue_btn" (click)="close(true)" *ngIf="termConditionFlag">YES, Continue</button>
        </div>
    </div>



</div>

<style>
    .mat-dialog-container {
        padding: 0 !important;
        border-radius: 10px;
    }

    .mainDiv {
        width: 670px;
        /* padding: 24px; */
    }

    .success_img_main_div {
        text-align: center;
        padding-top: 24px;
    }

    .completed_heading {
        text-align: center;
        color: #212121;
        font-size: 20px;
        font-weight: 500;
        margin-top: 15px;
    }

    .program_dtls_mail_div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        gap: 15px;
    }

    .program_dtls_img_div img {
        width: 70px;
        height: 70px;
    }

    .program_dtls_session_div {
        color: #212121;
        font-size: 15px;
        font-weight: 500;
    }

    .program_dtls_time_div {
        color: #4E4E4E;
        font-size: 14px;
    }

    .program_dtls_player_div {
        color: #4E4E4E;
        font-size: 14px;
    }


    .more_info_div {
        text-align: center;
        color: #4E4E4E;
        font-size: 14px;
        font-weight: 500;
        margin: 2rem 0;
    }




    .success_main {
        text-align: center;
    }

    .success_main button {
        width: 98px;
        height: 32px;
        background-color: #33DE81;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 500;
        border: none;
    }






    .term_btn_main_div {
        background-color: #F8F8F8;
        padding-bottom: 2rem;
        padding-top: 20px;
        border-top: 1px solid #E8E8E8;
    }

    .term_condition_main_div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .checkbox_div {
        width: 20px;
        height: 20px;
        border: 2px solid #212121;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .checkbox_div img {
        width: 12.9px;
    }

    .term_condition_txt {
        color: #5B5B5B;
        font-size: 15px;
        margin-left: 13px;
    }

    .term_condition_txt span {
        color: #0e43db;
        cursor: pointer;
    }

    .licences_info_btn_main_div {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .licences_info_btn_main_div .go_back_btn {
        width: 173px;
        height: 32px;
        border: 1px solid #585353;
        font-size: 15px;
        font-weight: 500;
        border-radius: 50px;
        background-color: transparent;
        color: #585353;
    }

    .licences_info_btn_main_div .continue_btn {
        width: 173px;
        height: 32px;
        border: none;
        font-size: 15px;
        font-weight: 500;
        border-radius: 50px;
        background-color: #33DE81;
        color: #000000;
    }

    .licences_info_btn_main_div .disabled_continue_btn {
        width: 173px;
        height: 32px;
        border: 1px solid #CBCBCB;
        font-size: 15px;
        font-weight: 500;
        border-radius: 50px;
        background-color: #E6E6E6;
        color: #969696;
        cursor: not-allowed;
    }







</style>