<div class="wh_component_div">
    <div style="flex: 1;">
        <!-- <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div> -->
        <div class="wh_header_main_div">
            <div>
                <div style="font-weight: 600;font-size: 22px;">Game set-up</div>
                <div>Please review the game details before you finish the setup !</div>
            </div>
            <div class="wh_header_right_sub">
                <div *ngIf="timerFlag">
                    <span>Time left to complete the set-up </span>
                    <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
                </div>
                <div><img src="../../../assets/profile.png" alt="" *ngIf="!showSideOrder" (click)="toggleFunc()"
                        style="cursor: pointer;"></div>
            </div>
            <div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
                <div class="header-drowdwon-content" (click)="goToAccountSetting()">
                    My Profile
                </div>
                <div class="header-drowdwon-content" (click)="goToMyGame()">
                    My Games
                </div>
                <div class="header-drowdwon-content" (click)="goGameReport()">
                    Games Reports
                </div>
                <div class="header-drowdwon-content" (click)="goToTCPage()">
                    Terms and conditions
                </div>
                <div class="header-drowdwon-content" (click)="logout()">
                    Logout
                </div>
            </div>
        </div>
        <div class="config_main_div">
            <div class="config_header_div">
                <div><img src="../../../assets/animal_farm.svg" alt="" style="width: 179px;"></div>
            </div>
            <div class="config_body_main_div">
                <div class="config_left_main_div">
                    <div class="ele_main_div">
                        <span>No. of players
                            <!-- <img placement="bottom" [ngbTooltip]="noOfPlayersTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>:</span>
                        <span class="pl_10">{{maxPlayerData}}</span>
                    </div>
                    <div class="ele_main_div">
                        <span>Players in a teams
                            <!-- <img placement="bottom" [ngbTooltip]="playerInTeamsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>:</span>
                        <span style="display: flex;align-items: flex-start;gap: 10px; flex-direction: column;">
                            <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                [(ngModel)]="perTeamPlyData" class="ele_input" (change)="fillNoPlyr()"
                                (keydown)="validateNumber($event)" [ngClass]="perTeamPlyData > 20 || perTeamPlyData > maxPlayerData ?'colorRedCss':''">
                            <span class="err_ele_span" *ngIf="perTeamPlyData > maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Players in a team can not be more than no of players</span>
                            </span>
                            <span class="err_ele_span" *ngIf="perTeamPlyData > 20 && perTeamPlyData <= maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Players in a team can not be more than 20</span>
                            </span>
                            <span class="err_ele_span" *ngIf="perTeamPlyData < 2 && perTeamPlyData != null && perTeamPlyData <= maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Players in a team can not be less than 2</span>
                            </span>
                            <span class="err_ele_span" *ngIf="noOfTeamsData > 20 && perTeamPlyData < 20 && perTeamPlyData <= maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>No of team can not be more than 20</span>
                            </span>
                        </span>
                    </div>
                    <div class="ele_main_div">
                        <span>Number of teams
                            <!-- <img placement="bottom" [ngbTooltip]="noOfTeamsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>:</span>
                        <span class="pl_10">{{noOfTeamsData}}</span>
                    </div>
                    <div class="ele_main_div">
                        <span>No. of rounds
                            <!-- <img placement="bottom" [ngbTooltip]="noOfRoundsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>&nbsp;</span>
                        <span>
                            <select name="noOfRound" id="" [(ngModel)]="totalOrder"
                                (change)="handleNoOfRound($event.target)" class="form-select custom-select"
                                style="width: 5rem;height: 31px;    padding: 3px 14px;">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <!-- <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option> -->
                            </select>
                        </span>
                    </div>
                    <div class="ele_main_div">
                        <span>Game time (Mins)
                            <!-- <img placement="bottom" [ngbTooltip]="gameTimeTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"> -->
                        </span>
                        <span>&nbsp;</span>
                        <span>
                            <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                [(ngModel)]="gameTimeData" class="ele_input" (keydown)="validateNumber($event)"
                                [ngClass]="gameTimeData >= maxDurationBySlots?'colorRedCss':''">
                            <span class="err_ele_span" style="margin-top: 10px;"
                                *ngIf="gameTimeData >= maxDurationBySlots">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Game time should be less than session duration</span>
                            </span>
                        </span>
                    </div>
                    <div class="animal-display-main-div">
                        <div class="animal-display-sub-div">
                            <div class="animal-display-row">
                                <div class="animal-display-row-ele">
                                    <img src="../../../assets/img/animalfarm/cow.svg" alt="">
                                    <div>Cow</div>
                                </div>
                                <div class="animal-display-row-ele">
                                    <img src="../../../assets/img/animalfarm/pig.svg" alt="">
                                    <div>Pig</div>
                                </div>
                                <div class="animal-display-row-ele">
                                    <img src="../../../assets/img/animalfarm/dog.svg" alt="">
                                    <div>Dog</div>
                                </div>

                            </div>
                            <div class="animal-display-row">
                                <div class="animal-display-row-ele">
                                    <img src="../../../assets/img/animalfarm/duck.svg" alt="">
                                    <div>Duck</div>
                                </div>
                                <div class="animal-display-row-ele">
                                    <img src="../../../assets/img/animalfarm/goat.svg" alt="">
                                    <div>Goat</div>
                                </div>
                                <div class="animal-display-row-ele">
                                    <img src="../../../assets/img/animalfarm/hen.svg" alt="">
                                    <div>Rooster</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="config_right_main_div">
                    <div class="config_right_sub_div">
                        <div style="display: flex;justify-content: space-between;">
                            <div>
                                <span class="config_right_heading">Target Mix</span>
                                <img src="../../../assets/img/tooltip.svg" style="cursor: pointer;"
                                    (click)="showSideOrder = true">
                            </div>
                            <div><span class="config_right_heading" style="margin: 0;">Time (mins)</span></div>
                        </div>

                        <div>
                            <div class="grid-3" *ngFor="let item of noOfRoundEmptyArray;let i = index">
                                <div>Round {{i+1}} :</div>
                                <div>
                                    <div class="target_order_time_main_div">
                                        <select name="" id="" class="form-select round-select"
                                            [(ngModel)]="roundData[i]['value']"
                                            (change)="handleLevelChange($event.target,i)" style="min-width: 147px;">
                                            <option value="1">Target Mix 1</option>
                                            <option value="2">Target Mix 2</option>
                                            <option value="5">Target Mix 3</option>
                                            <option value="3">Target Mix 4</option>
                                        </select>
                                        <div style="text-align: center;">
                                            <input type="number" class="time-input-round"
                                                [(ngModel)]="roundData[i]['time']"
                                                (change)="handleRoundTime($event.target,i)" min="1"
                                                oninput="javascript: if(this.value == 0) this.value=''">
                                        </div>

                                    </div>
                                    <span class="err_ele_span" *ngIf="orderError[i]">
                                        <img src="../../../assets/error_icon.svg" alt="">
                                        <span>Please select the target mix</span>
                                    </span>
                                    <span class="err_ele_span" *ngIf="timeError[i]">
                                        <img src="../../../assets/error_icon.svg" alt="">
                                        <span>Please fill the time target mix</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                <input style="width: 27%; max-width: 200px" type="image" src="../../../assets/img/save&conti-btn.svg"
                    (click)="saveAnimalFarmConfig()"
                    *ngIf="!((perTeamPlyData > maxPlayerData) ||
                    (perTeamPlyData > 20 && perTeamPlyData <= maxPlayerData) ||
                    (perTeamPlyData < 2 && perTeamPlyData != null && perTeamPlyData <= maxPlayerData) ||
                    (noOfTeamsData > 20 && perTeamPlyData < 20 && perTeamPlyData <= maxPlayerData)) && perTeamPlyData && !(gameTimeData >= maxDurationBySlots) && gameTimeData && roundData[0]['time'] && roundData[1]['time'] && roundData[2]['time'] && roundData[3]['time']; else disabled_btn_af"
                    />
                <ng-template #disabled_btn_af>
                    <input style="width: 27%; max-width: 200px" type="image" src="../../../assets/save&countinue_disabled.svg" (click)="this.roundValidation()"/>
                </ng-template>
                
            </div>
        </div>

    </div>
    <div class="order_side_div" *ngIf="showSideOrder">
        <div><img src="../../../assets/close-circle-svgrepo-com.svg" alt="" style="width: 35px;cursor: pointer;"
                (click)="showSideOrder = false"></div>
        <div style="margin-top: 1rem;" class="sideOrderHeading">
            <span>&nbsp;</span>
        </div>
        <div class="">
            <div style="width:100% ;">
                <div>
                    <div class="order-number">
                        Target mix 1
                    </div>
                    <div class="order-content">
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">10 </span><span class="blue-bold">Animals</span> at
                                least <span style="font-weight: 600;"> 1</span> of all each color </li>
                        </div>
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">5 </span><span class="blue-bold">Birds</span> at least
                                <span style="font-weight: 600;">
                                    1</span> of all each color
                            </li>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="order-number">
                        Target mix 2
                    </div>
                    <div class="order-content">
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">20 </span> Black and Brown <span
                                    class="blue-bold">Animals</span></li>
                        </div>
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">10 </span> White and Grey <span
                                    class="blue-bold">Animals</span></li>
                        </div>
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">5 </span> Black and Brown <span
                                    class="blue-bold">Birds</span></li>
                        </div>
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">15 </span> White and Grey <span
                                    class="blue-bold">Birds</span></li>
                        </div>

                    </div>
                </div>

            </div>
            <div style="width:100% ;">
                <div>
                    <div class="order-number">
                        Target mix 3
                    </div>
                    <div class="order-content">
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">40 </span>Black and Brown <span
                                    class="blue-bold">Creatures</span> </li>
                        </div>
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">40 </span>White and Grey <span
                                    class="blue-bold">Creatures</span> </li>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="order-number">
                        Target mix 4
                    </div>
                    <div class="order-content">
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">45 </span><span class="blue-bold">Animals</span> +<span
                                    style="font-weight: 600;"> 25
                                </span> <span class="blue-bold">Birds</span></li>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div>
    <div>
        <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div>
        <div class="main-container">
            <div>
                <div class="left-div">
                    <div style="flex: 1.5;">
                        <div style="font-weight: 500; margin-left: 1rem; font-size: 15px;">Team Configuration</div>
                        <div class="team-config-main-div">
                            <div class="element-input-div">
                                <div>
                                    <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg">
                                    <li>Maximum Players</li>
                                </div>
                                <input type="number" [(ngModel)]="maxPlayerData" min="1"
                                    oninput="javascript: if(this.value == 0) this.value=''" [disabled]="disabledFlag"
                                    [ngClass]="disabledFlag ? 'disabledInput':''">
                            </div>
                            <div class="element-input-div">
                                <div>
                                    <img placement="bottom" [ngbTooltip]="noteams" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg">
                                    <li>Number of Teams</li>
                                </div>
                                <input type="number" [(ngModel)]="noOfTeamsData" min="1"
                                    oninput="javascript: if(this.value == 0) this.value=''" [disabled]="disabledFlag"
                                    [ngClass]="disabledFlag ? 'disabledInput':''">
                            </div>
                            <div class="element-input-div">
                                <div>
                                    <img placement="bottom" [ngbTooltip]="noplayersteam" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg">
                                    <li>Number of players per team</li>
                                </div>
                                <input type="number" [(ngModel)]="perTeamPlyData" min="1"
                                    oninput="javascript: if(this.value == 0) this.value=''" [disabled]="disabledFlag"
                                    [ngClass]="disabledFlag ? 'disabledInput':''">
                            </div>
                            <div class="element-input-div">
                                <div>
                                    <img placement="bottom" [ngbTooltip]="gametime" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg">
                                    <li>Game Time (mins)</li>
                                </div>
                                <input type="number" [(ngModel)]="gameTimeData" min="1"
                                    oninput="javascript: if(this.value == 0) this.value=''">
                            </div>
                            <div class="element-input-div">
                                <div>
                                    <img placement="bottom" [ngbTooltip]="sessionduration" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg">
                                    <li>Session Duration (mins)</li>
                                </div>
                                <input type="number" [(ngModel)]="sessionTimeData" min="1"
                                    oninput="javascript: if(this.value == 0) this.value=''">
                            </div>
                        </div>
                    </div>
                    <div style="flex: 1;">
                        <div>&nbsp;</div>
                        <div class="animal-display-main-div">
                            <div class="animal-display-sub-div">
                                <div class="animal-display-row">
                                    <div class="animal-display-row-ele">
                                        <img src="../../../assets/img/animalfarm/cow.svg" alt="">
                                        <div>Cow</div>
                                    </div>
                                    <div class="animal-display-row-ele">
                                        <img src="../../../assets/img/animalfarm/pig.svg" alt="">
                                        <div>Pig</div>
                                    </div>
                                </div>
                                <div class="animal-display-row">
                                    <div class="animal-display-row-ele">
                                        <img src="../../../assets/img/animalfarm/duck.svg" alt="">
                                        <div>Duck</div>
                                    </div>
                                    <div class="animal-display-row-ele">
                                        <img src="../../../assets/img/animalfarm/dog.svg" alt="">
                                        <div>Dog</div>
                                    </div>
                                </div>
                                <div class="animal-display-row">
                                    <div class="animal-display-row-ele">
                                        <img src="../../../assets/img/animalfarm/goat.svg" alt="">
                                        <div>Goat</div>
                                    </div>
                                    <div class="animal-display-row-ele">
                                        <img src="../../../assets/img/animalfarm/hen.svg" alt="">
                                        <div>Rooster</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="left-div-bootom">
                    <div class="left-bottom-head">
                        <div style="font-weight: 500; font-size: 14px;">Select target mix for your Round</div>
                        <div class="left-bottom-head-select">
                            <div>Number of Rounds</div>
                            <select name="noOfRound" id="" class="form-select" [(ngModel)]="totalOrder"
                                (change)="handleNoOfRound($event.target)">
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <div class="left-bottom-body">
                        <div class="grid-4">
                            <div>&nbsp;</div>
                            <div style="text-align: center;font-size: 13px;font-weight: 500;">Difficulty Level</div>
                            <div style="text-align: center;font-size: 13px;font-weight: 500;">Mix</div>
                            <div style="text-align: center;font-size: 12px;font-weight: 500; width: 70px;">Time (Mins)
                            </div>
                        </div>
                        <div class="grid-4" *ngFor="let item of noOfRoundEmptyArray;let i = index">
                            <div style="font-size: 14px; font-weight: 500;">Round {{i+1}} :</div>
                            <div>
                                <select name="" id="" class="form-select round-select"
                                    [(ngModel)]="roundData[i]['order']" (change)="handleLevelChange($event.target,i)">
                                    <option value="Simple" selected>Simple</option>
                                    <option value="Advanced">Advanced</option>
                                </select>
                            </div>
                            <div>
                                <select name="" id="" class="form-select round-select"
                                    [(ngModel)]="roundData[i]['level']" (change)="handelOrderChange($event.target, i)">
                                    <option value="Order 1" selected>Mix 1</option>
                                    <option value="Order 2">Mix 2</option>
                                    <option value="Order 3">Mix 3</option>
                                </select>
                            </div>
                            <div style="text-align: center;">
                                <input type="number" class="time-input-round" [(ngModel)]="roundData[i]['time']"
                                    (change)="handleRoundTime($event.target,i)" min="1"
                                    oninput="javascript: if(this.value == 0) this.value=''">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <div style="font-weight: 500; font-size: 15px;">Target Mix</div>
                <div class="order">
                    <div class="order-header">
                        <div class="order-header-btn" (click)="order('simple')"
                            [ngClass]="{selectedOrder:selectedOrder=='simple'} ">
                            EASY
                        </div>
                        <div class="order-header-btn " (click)="order('advanced')"
                            [ngClass]="{selectedOrder:selectedOrder=='advanced'} ">
                            Difficult
                        </div>
                    </div>

                    <div class="order-body">
                        <div style="width:100% ;" *ngIf="selectedOrder=='simple'">
                            <div style="margin-bottom: 20px;">
                                <div class="order-number">
                                    Mix 1
                                </div>
                                <div class="order-content">
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">10 </span><span
                                                class="blue-bold">Animals</span> at least <span
                                                style="font-weight: 600;"> 1</span> of all each color </li>
                                    </div>
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">5 </span><span
                                                class="blue-bold">Birds</span> at least <span style="font-weight: 600;">
                                                1</span> of all each color </li>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-bottom: 20px;">
                                <div class="order-number">
                                    Mix 2
                                </div>
                                <div class="order-content">
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">20 </span> Black and Brown <span
                                                class="blue-bold">Animals</span></li>
                                    </div>
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">10 </span> White and Grey <span
                                                class="blue-bold">Animals</span></li>
                                    </div>
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">5 </span> Black and Brown <span
                                                class="blue-bold">Birds</span></li>
                                    </div>
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">15 </span> White and Grey <span
                                                class="blue-bold">Birds</span></li>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div class="order-number">
                                    Mix 3
                                </div>
                                <div class="order-content">
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">45 </span><span
                                                class="blue-bold">Animals</span> +<span style="font-weight: 600;"> 25
                                            </span> <span class="blue-bold">Birds</span></li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width:100% ;" *ngIf="selectedOrder=='advanced'">
                            <div style="margin-bottom: 20px;">
                                <div class="order-number">
                                    Mix 1
                                </div>
                                <div class="order-content">
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">12 </span>of each <span
                                                class="blue-bold">Creatures</span> in 3 colours</li>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-bottom: 20px;">
                                <div class="order-number">
                                    Mix 2
                                </div>
                                <div class="order-content">
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">40 </span>Black and Brown <span
                                                class="blue-bold">Creatures</span> </li>
                                    </div>
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">40 </span>White and Grey <span
                                                class="blue-bold">Creatures</span> </li>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="order-number">
                                    Mix 3
                                </div>
                                <div class="order-content">
                                    <div class="content-txt">
                                        <li> <span style="font-weight: 600;">50 </span><span
                                                class="blue-bold">Animals</span> +<span style="font-weight: 600;"> 30
                                            </span> <span class="blue-bold">Birds</span></li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                        <input style="width: 27%; max-width: 200px;" type="image"
                            src="../../../assets/img/save&conti-btn.svg" (click)="saveAnimalFarmConfig()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<style>
    .disabledInput {
        background-color: #dddddd !important;
    }

    .header-drowdwon {
        position: absolute;
        right: 2px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 11px 11px;
        opacity: 1;
        border-top: none;
        z-index: 1111;
        box-shadow: 0px 3px 6px #00000029;
    }

    .header-drowdwon-content {
        padding: 5px 15px;
        cursor: pointer;
    }

    .header-drowdwon-content:hover {
        background-color: #C6E8C9;
    }

    .header-drowdwon-content:last-of-type:hover {
        border-radius: 0px 0px 11px 11px;
    }
</style>